<template>
  <div class='container mx-auto'>
    <prismic-rich-text :field="slice.primary.text"/>
  </div>
</template>

<script>
export default {
  props: ['slice'],
  name: 'text-slice'
}
</script>